import React, { HtmlHTMLAttributes, useState } from "react";
import "../../styles/global.scss";
import * as styles from "./common.module.scss";
import close from "../../images/close-small-cookies.png";

const Cookies: React.FC = () => {
  const [cookiesActive, setCookiesActive] = useState(true);

  const addcookies = () => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    const activevalue = "false";
    const cookieValue = `${activevalue}; expires=${expiryDate.toUTCString()}; path=/`;
    document.cookie = "cookies=" + cookieValue;
    setCookiesActive(false);
  };

  return (
    <>
      <div
        className={`cookies ${styles.cookies} ${
          cookiesActive ? styles.active : styles.inactive
        }  `}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 d-flex align-items-center">
              <p>
                This website uses cookies from us and our business partners to
                enhance your browsing experience. For more information,
                including how to manage your cookie settings, please check our
                our{" "}
                <span
                  // type="button"
                  className="cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  onClick={() => {
                    setCookiesActive(false);
                  }}
                >
                  Cookies Policy{" "}
                </span>
                and{" "}
                <span
                  className="cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#pp"
                >
                  Privacy Policy
                </span>
                .
              </p>
            </div>
            <div className="col-md-2 d-flex align-items-center cookiesBtn">
              <button
                className={`${styles.button} align-items-center fs-16 fw-7 lh-7 text-white`}
                onClick={() => {
                  addcookies();
                }}
              >
                I understand{" "}
              </button>
            </div>

            <div className="col-md-1 d-flex align-items-center justify-content-center closeBtn cursor-pointer">
              <img
                src={close}
                className="img-fluid"
                onClick={() => {
                  setCookiesActive(false);
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade cookiesPop"
        id="pp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-xl cookiesPop">
          <div className="modal-content">
            <div className="modal-header">
              <h3
                className="modal-title modal-title-privacy"
                id="staticBackdropLabel"
              >
                PRIVACY POLICY
              </h3>

              {/* <div>
                                <h5>PRIVACY STATEMENT</h5>
                            </div> */}

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              This Privacy Policy explains how Bahwan Cybertek Group
              (hereinafter referred to as “Bahwan CyberTek”, “we”, “us”, “our”)
              may collect, use, store, disclose, discard, or otherwise process
              your data including personal data provided when using our websites
              and describes the rights you have with respect to your personal
              data.
              <br />
              Bahwan CyberTek is committed to providing the highest level of
              protection regarding the processing of their
              vendors’/clients’/customers’/job applicants’/website visitors’
              personal data based on applicable data protection laws and
              regulations at respective locations. In general, you can visit the
              Bahwan CyberTek website without providing personal data. However,
              there are times when Bahwan CyberTek will request personal data.
              <br /> <br />
              <strong>Personal Data</strong> <br /> <br />
              In this Privacy Policy, “personal data” is any data which relates
              to an individual who may be identified from that data, or from a
              combination of a set of data, and other information which is or
              may be in possession of Bahwan CyberTek, for instance information
              about your identity and contact details (such as name, email-ID,
              contact number).
              <br />
              <br />
              <strong>Your rights in relation to personal data</strong> <br />
              You have the following rights in relation to your personal data:
              <br />
              <ul>
                <li>
                  To access the personal data held by Bahwan CyberTek about you.
                </li>
                <li>
                  To have your personal data corrected, for example, if it is
                  incomplete or incorrect.
                </li>
                <li>
                  To opt out of receiving marketing communications at any time
                </li>
                <li>
                  To restrict or object to the processing of personal data or
                  request erasing personal data (in certain circumstances and
                  subject to applicable law)
                </li>
                <li>
                  To receive a copy of the personal data which you have provided
                  to Bahwan CyberTek (in certain circumstances and subject to
                  applicable law)
                </li>
              </ul>
              <br />
              <strong>Purposes for which we process Personal Data</strong>
              Bahwan CyberTek may process your personal data for legitimate
              purposes as follows, but not limited to:
              <ul>
                <li>
                  Marketing / promoting and providing products and services.
                </li>
                <li>Monitoring and improving our website and its content.</li>
                <li>
                  Conducting market research and surveys with the aim of
                  improving / demonstrating our products and services.
                </li>
                <li>
                  Complying with applicable local or foreign law, regulation,
                  policy, voluntary codes, directive.
                </li>
                <li>
                  Establishing, exercising, or defending legal rights in
                  connection with legal proceedings (including any prospective
                  legal proceedings) and seeking professional or legal advice in
                  relation to such legal proceedings.
                </li>
              </ul>
              <br />
              Bahwan CyberTek processes your personal data for the performance
              of the contracts, employment concluded with you, compliance with
              applicable legal or regulatory obligations or Bahwan CyberTek’s
              legitimate interests to provide you with adequate and qualitative
              information about products and services and to prevent against any
              excessive risk.
              <br />
              <br />
              <strong>Transfer of Personal Data </strong>
              <p>
                Bahwan CyberTek may transfer personal data, to any other body
                corporate or a person located in any other country. Bahwan
                CyberTek shall ensure that data protection is adhered to by
                Bahwan CyberTek as per the global rules and regulations. The
                transfer may be allowed only if it is necessary for the
                performance of the lawful contract between Bahwan CyberTek or
                any person on its behalf.
              </p>
              <br />
              <strong>Data Retention </strong>
              <p>
                Personal Data is important to us and Bahwan CyberTek has
                invested significantly in technology and processes to protect
                the safeguarding and confidentiality of your personal data. If
                Bahwan CyberTek engages external service providers to act as
                processor, we ensure that they adhere to the same standards as
                Bahwan CyberTek. Irrespective of where your personal information
                is transferred or stored, we take all necessary steps to ensure
                that personal data is kept secure.
              </p>
              <br />
              <strong>Changes to this Privacy Policy</strong>
              <p>
                Bahwan CyberTek may update this policy from time to time. The
                use of the Bahwan CyberTek Websites and any products and
                services supplied are subject to our Terms and Conditions.
              </p>
              <br />
              <strong>Contact Details </strong>
              <p>
                If you have any questions regarding this privacy policy or the
                protection of your personal data, you may reach out to Bahwan
                CyberTek Data Privacy Officer at <u>DPO@bahwancybertek.com</u>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal modal-cookies fade cookiesPop"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Cookie Policy{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setCookiesActive(true);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <p>
                When you browse this website, cookies and other trackers are
                placed on your terminal (computer, mobile, tablet). This Policy
                lays out the cookies and other trackers we use, the reasons why
                we use them, and the tools at your disposal to manage them.
              </p>
              <br />
              <strong>1. WHAT ARE COOKIE?</strong>
              <p>
                Cookies are small text files or data files that are downloaded
                to a user’s device when they visit the Bahwan CyberTek website,
                either as a visitor or as Bahwan CyberTek customer. These small
                text files are then redirected to the Bahwan CyberTek website
                (from which they originate) on each new visit. Cookies are
                widely used because they allow websites, such as Bahwan
                CyberTek’s, to recognise a visitor’s devices and thus improve
                the usability of the website in question. Cookies are widely
                used to do different jobs, like letting you navigate between
                pages efficiently, remembering your preferences and generally
                improving your browsing experience. Some cookies are deleted
                when you close down your browser. These are known as session
                cookies. Others remain on your device until they expire or you
                delete them from your cache. These are known as persistent
                cookies and enable us to remember things about you as a
                returning visitor. This website uses both session and persistent
                cookies.
              </p>
              <strong>2. WHO PLACES AND USES COOKIES ON THE WEBSITE?</strong>
              <p>
                Cookies are placed by Bahwan CyberTek, (a small two liners intro
                on Bahwan CyberTek if required) or its technical service
                providers on the website and are used for its own purposes.
                Bahwan CyberTek is consequently responsible for the processing
                of said cookies as Data Controller. Other cookies are issued and
                operated by our partners or third-party companies for purposes
                determined by such partners and third-party companies. In such
                cases, the latter are responsible for processing said cookies.
                Bahwan CyberTek shall be considered as a joint controller
                whenever it authorises the placement of third-party cookies on
                the website for purposes defined jointly with its partners or
                third-party companies. Notwithstanding the foregoing, Bahwan
                CyberTek shall not be liable for any further processing by the
                partner or third-party company involving the processing of
                personal data, for which said third party shall remain a
                separate data controller.
              </p>
              <strong> 3. WHY DO WE USE COOKIES?</strong>
              <p>
                Bahwan CyberTek uses cookies to collect information about the
                browsing of visitors to its website: information from the
                visitor’s browser (browser type and language), the IP (Internet
                Protocol) address of the visitor’s terminal, and information
                derived from this address such as the geographical location and
                identification numbers of the terminal, the visitor’s Internet
                service provider, the pages and files consulted by the visitor,
                the visitor’s operating system, and the date and time of the
                visitor’s browsing on the website. Bahwan CyberTek use cookies
                to provide an online service more suited to the device you
                connect from, as well as to enhance your online experience by
                keeping track of your behaviour within the site and to make sure
                you do not see the same special offers repeatedly. Some cookies
                are required for technical reasons in order for our Websites to
                operate, and we refer to these as "essential" or "strictly
                necessary" cookies.
              </p>
              <br />
              <p>
                The above information allows Bahwan CyberTek to administer its
                website in general, and more specifically, allows it to:
              </p>
              <ul>
                <li>
                  Generate anonymous aggregate data about visitors’ use of the
                  website and the volume of statistical information about them;
                  this statistical data covers the use and performance of the
                  website so that it can be monitored and improved
                </li>
                <li>
                  Facilitate visitors’ browsing experience on the website,
                  including so as to allow registered users to access secure
                  parts of the website
                </li>
                <li>Diagnose technical problems</li>
                <li>
                  Enable visitors to share Bahwan CyberTek web pages on social
                  networking platforms
                </li>
              </ul>
              <p>
                Bahwan CyberTek may receive visitor information from third
                parties and aggregate this information with existing visitor
                data in order to analyse its records, improve the online
                experience of visitors, identify new prospects and personalise
                information.
              </p>
              <br />
              <strong>4. WHAT COOKIES DO WE USE AND WHY?</strong>
              <p>
                The full list of cookies used on the website is available in the
                cookie preference centre. Visitors can choose their own settings
                directly using this centre, which allows them to consent to the
                use of different categories of cookies.
              </p>
              <strong>5. YOUR CHOICES REGARDING COOKIES</strong>
              <strong>5.1 Setting your preferences</strong>
              <p>Some of the cookies we use are subject to your consent.</p>
              <p>
                {" "}
                We have set up a cookie preference centre that allows visitors
                to express their choices on the website.
              </p>
              <p>
                You can express your choice on the use of cookies as a whole, or
                set your preferences for each individual cookie purpose. You can
                change these choices at any time by clicking on “Cookie
                preferences” at the bottom of each page of the website.
              </p>
              <p>
                No cookie shall be installed on your terminal without your prior
                consent, with the exception of certain cookies whose sole
                purpose is to enable or facilitate electronic communications, or
                which are strictly necessary for the provision of an online
                communication service which you have expressly requested.
              </p>
              <p>
                Your acceptance or rejection of cookies is kept for a period of
                12 months, regardless of the type of cookie in question. Your
                consent shall be requested again at the end of this 12-month
                period when the information banner is displayed.
              </p>
              <br />
              <strong>5.2 Setting up your browser</strong>
              <p>
                You can configure your browser so that cookies are saved or
                rejected on your terminal, either systematically or based on the
                sender of the cookies.
              </p>
              <p>
                However, we would like to draw your attention to the fact that
                setting your browser does not exempt you from making a choice as
                to whether or not to allow the website to place and read
                cookies.
              </p>
              <p>
                Your browser’s settings may also have negative effects on your
                browsing experience. If you refuse to accept technical cookies
                on your terminal, or if you delete the cookies stored on your
                terminal, you shall no longer be able to benefit from a certain
                number of features which are nevertheless necessary for browsing
                on certain areas of the website.
              </p>
              <p>
                In such case, we decline all liability for any consequences
                related to impaired performance of the website resulting from us
                not being able to record or consult those cookies necessary for
                the website to function which you have rejected or deleted.
              </p>

              <br />
              <strong> 6.PROCESSING OF YOUR PERSONAL DATA</strong>
              <p>
                In our capacity as data controller, we may collect your personal
                data through the cookies and other trackers we use on the
                website. For more information on how your personal data is
                processed in this context, please consult our Privacy policy.
              </p>
              <br />
              <strong>7. CONTRACT</strong>
              <p>
                If you have any questions about the cookies used on the website,
                you can write to us at <u>global@bahwancybertek.com</u>
              </p>
              <p>
                The contact details of Bahwan CyberTek Data Protection Officer
                are as follows: <u>dpo@BCT.com</u>
              </p>

              <br />
              <strong>8. POLICY UPDATE</strong>
              <p>
                We may change this Policy from time to time, for example so as
                to take account of legal changes, technological advances and
                good business practice. We shall notify you in the event of any
                material changes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cookies;
