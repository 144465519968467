
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import config from './cognito-config';

const poolData = {
  UserPoolId: config.userPoolId,
  ClientId: config.userPoolWebClientId,
  // secretId : config.clientSecretID
};

const userPool = new CognitoUserPool(poolData);

export { userPool };
