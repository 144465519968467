
interface CognitoConfig {
    region: any;
    userPoolId: any;
    userPoolWebClientId: any;
    // clientSecretID: string;
  }

  const config: CognitoConfig = {
    region: process.env.GATSBY_APP_AWS_REGION,
    userPoolId: process.env.GATSBY_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_APP_AWS_APP_CLIENT_ID,
    // clientSecretID: '1d0upmo7lubsjdo5hda2oh278bbm24f8keauobohag2ifo03u98r'
  };
  
  export default config;
  